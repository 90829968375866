<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-card class="elevation-1">
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('title.transferencia_verba', 1) }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-breadcrumbs
            style="font-size:130%;font-weight:bold!important;font-color:black;">
          {{ $t('label.origem') }}
        </v-breadcrumbs>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="tipo_verba_origem"
              name="tipo_verba_origem"
              :items="tipoVerbaListaOrigem"
              item-text="nome"
              item-value="id"
              clearable
              :disabled="somenteLeitura"
              @input="changeTipoVerbaOrigem"
              v-model="novoAjuste.idTipoVerbaOrigem"
              :label="`${$tc('label.tipo_verba', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="orcamento_origem"
              name="orcamento_origem"
              :items="orcamentoListaOrigem"
              item-text="descricao"
              item-value="id"
              clearable
              @input="changeOrcamentoOrigem"
              :disabled="somenteLeitura || disableOrcamentoOrigem"
              v-model="novoAjuste.idOrcamentoOrigem"
              :label="`${$tc('label.orcamento', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="periodo_orcamentario_origem"
              name="periodo_orcamentario_origem"
              :items="periodoOrcamentarioListaOrigem"
              item-text="descricao"
              item-value="id"
              clearable
              @input="changePeriodoOrcamentarioOrigem"
              :disabled="somenteLeitura || disablePeriodoOrcamentarioOrigem"
              v-model="novoAjuste.idPeriodoOrcamentarioOrigem"
              :label="`${$tc('label.periodo_orcamentario', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-alert type="warning"
                 v-if="configuracaoOrigem
                  && !configuracaoOrigem.permiteTransferenciaAjusteVerbaDiferente
                   && novoAjuste.idOrcamentoOrigem"
                 elevation="2"
        >
          {{ this.$t('errors.transferencia.nao_permite_transf_tipo_verba_diferente') }}
        </v-alert>
        <v-alert type="error"
                 v-if="configuracaoOrigem && !configuracaoOrigem.permiteTransferencia"
                 elevation="2"
        >
          {{ this.$t('errors.transferencia.nao_permite_transferencia') }}
        </v-alert>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="tipo_linha_orcamento_origem"
              name="tipo_linha_orcamento_origem"
              :items="linhaInvestimentoListaOrigem"
              item-text="descricao"
              item-value="id"
              clearable
              @input="changeLinhaInvestimentoOrigem"
              :disabled="somenteLeitura || disableLinhaInvestimentoOrigem"
              v-model="novoAjuste.idLinhaInvestimentoOrigem"
              :label="`${$tc('label.linha_de_investimento', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="configuracaoOrigemCarregada && configuracaoOrigem.rateioDivisao">
            <v-autocomplete
              id="divisao_conta_origem"
              name="divisao_conta_origem"
              :items="divisoesContaOrigem"
              item-text="nome"
              item-value="id"
              clearable
              v-model="novoAjuste.idControladorTradeOrigem"
              @input="changeDivisoesContaOrigem"
              :disabled="somenteLeitura || disableDivisoesContaOrigem"
              :label="`${$tc('label.divisao', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <input-decimal
                v-if="configuracaoOrigemCarregada && configuracaoOrigem.ehPercentual"
                :label="$t('label.valor_planejado')"
                disabled
                v-model="valorPlanejadoOrigem"
                suffix="%"
                type="Number"
                min="0">
            </input-decimal>
            <input-money
              v-else
              id="valor_planejado_origem"
              name="valor_planejado_origem"
              :label="$t('label.valor_planejado')"
              disabled
              v-model="valorPlanejadoOrigem">
            </input-money>
          </v-col>
          <v-col cols="12" sm="6" md="4"
            v-if="novoAjuste.status !== 'APROVADO' && novoAjuste.status !== 'REPROVADO'">

            <input-decimal
                v-if="configuracaoOrigemCarregada && configuracaoOrigem.ehPercentual"
                :label="$t('label.saldo_disponivel')"
                disabled
                v-model="saldoDisponivelOrigem"
                suffix="%"
                type="Number"
                min="0">
            </input-decimal>
            <input-money
               v-else
              id="saldo_disponivel__origem"
              name="saldo_disponivel_origem"
              :label="$t('label.saldo_disponivel')"
              disabled
              v-model="saldoDisponivelOrigem">
            </input-money>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <input-decimal
              v-if="configuracaoOrigemCarregada && configuracaoOrigem.ehPercentual"
              :label="`${$t('label.valor_transferencia')} *`"
              :rules="[rules.required, rules.valorAjusteNegativo]"
              @input="validaValor"
              v-model="novoAjuste.valor"
              suffix="%"
              :disabled="somenteLeitura || disablePeriodoOrcamentarioOrigem"
              type="Number"
              min="0">
            </input-decimal>
            <input-money
              v-else
              :label="`${$t('label.valor_transferencia')} *`"
              :rules="[rules.required, rules.valorAjusteNegativo]"
              :disabled="somenteLeitura || disablePeriodoOrcamentarioOrigem"
              @input="validaValor"
              v-model="novoAjuste.valor">
            </input-money>
          </v-col>
        </v-row>
        <v-alert type="error"
            v-if="mostrarAlertaSaldoNegativo"
            elevation="2"
          >
            {{ this.$t('errors.transferencia.saldo_menor_vlr_transferido') }}
        </v-alert>
        <v-spacer></v-spacer>
        <v-breadcrumbs style="font-size:130%;font-weight:bold!important;font-color:black;">{{ $t('label.destino') }}</v-breadcrumbs>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="tipo_verba_destino"
              name="tipo_verba_destino"
              :items="tipoVerbaListaDestino"
              item-text="nome"
              item-value="id"
              clearable
              :disabled="somenteLeitura || disableTipoVerbaDestino"
              @input="changeTipoVerbaDestino"
              v-model="novoAjuste.idTipoVerbaDestino"
              :label="`${$tc('label.tipo_verba', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="orcamento_destino"
              name="orcamento_destino"
              :items="orcamentoListaDestino"
              item-text="descricao"
              item-value="id"
              clearable
              @input="changeOrcamentoDestino"
              :disabled="somenteLeitura || disableOrcamentoDestino"
              v-model="novoAjuste.idOrcamentoDestino"
              :label="`${$tc('label.orcamento', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="periodo_orcamentario_destino"
              name="periodo_orcamentario_destino"
              :items="periodoOrcamentarioListaDestino"
              item-text="descricao"
              item-value="id"
              clearable
              :disabled="somenteLeitura || disablePeriodoOrcamentarioDestino"
              v-model="novoAjuste.idPeriodoOrcamentarioDestino"
              @input="changePeriodoOrcamentarioDestino"
              :label="`${$tc('label.periodo_orcamentario', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-alert type="error"
          v-if="isOrcamentoDestinoIncompativel"
          elevation="2"
        >
          {{ this.$t('errors.transferencia.nao_permite_transf_tipo_verba_diferente') }}
        </v-alert>
        <v-alert type="error"
          v-if="configuracaoDestino && !configuracaoDestino.permiteTransferencia"
          elevation="2"
        >
          {{ this.$t('errors.transferencia.nao_permite_transferencia') }}
        </v-alert>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="tipo_linha_orcamento_destino"
              name="tipo_linha_orcamento_destino"
              :items="linhaInvestimentoListaDestino"
              item-text="descricao"
              item-value="id"
              clearable
              @input="changeLinhaInvestimentoDestino"
              :disabled="somenteLeitura || disableLinhaInvestimentoDestino"
              v-model="novoAjuste.idLinhaInvestimentoDestino"
              :label="`${$tc('label.linha_de_investimento', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="configuracaoDestinoCarregada && configuracaoDestino.rateioDivisao">
            <v-autocomplete
              id="divisao_conta_destino"
              name="divisao_conta_destino"
              :items="divisoesContaDestino"
              item-text="nome"
              item-value="id"
              clearable
              v-model="novoAjuste.idControladorTradeDestino"
              @input="changeDivisoesContaDestino"
              :disabled="somenteLeitura || disableDivisoesContaDestino"
              :label="`${$tc('label.divisao', 1)} *`"
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <input-decimal
                v-if="configuracaoDestinoCarregada && configuracaoDestino.ehPercentual"
                :label="$t('label.valor_planejado')"
                disabled
                v-model="valorPlanejadoDestino"
                suffix="%"
                type="Number"
                min="0">
            </input-decimal>
            <input-money
              v-else
              id="valor_planejado_destino"
              name="valor_planejado_destino"
              :label="$t('label.valor_planejado')"
              disabled
              v-model="valorPlanejadoDestino">
            </input-money>
          </v-col>
          <v-col cols="12" sm="6" md="4"
            v-if="novoAjuste.status !== 'APROVADO'
             && novoAjuste.status !== 'REPROVADO'">

            <input-decimal
                v-if="configuracaoDestinoCarregada && configuracaoDestino.ehPercentual"
                :label="$t('label.saldo_disponivel')"
                disabled
                v-model="saldoDisponivelDestino"
                suffix="%"
                type="Number"
                min="0">
            </input-decimal>
            <input-money
              v-else
              id="saldo_disponivel_destino"
              name="saldo_disponivel_destino"
              :label="$t('label.saldo_disponivel')"
              disabled
              v-model="saldoDisponivelDestino">
            </input-money>
          </v-col>
          <v-col cols="12" sm="6" md="4"
            v-if="novoAjuste.status !== 'APROVADO'
             && novoAjuste.status !== 'REPROVADO'">
            <input-decimal
                v-if="configuracaoDestinoCarregada && configuracaoDestino.ehPercentual"
                :label="`${$t('label.saldo_pos_transferencia')} *`"
                v-model="saldoPosTransferencia"
                suffix="%"
                disabled
                type="Number"
                min="0">
            </input-decimal>
            <input-money
              v-else
              id="saldo_pos_transferencia"
              name="saldo_pos_transferencia"
              :label="$t('label.saldo_pos_transferencia')"
              disabled
              v-model="saldoPosTransferencia">
            </input-money>
          </v-col>
        </v-row>
        <v-alert type="error"
          v-if="mostrarAlertaMesmoPeriodoOrcamentario"
          elevation="2"
        >
          {{ this.$t('errors.transferencia.mesma_linha') }}
        </v-alert>
        <v-alert type="error"
          v-if="mostrarAlertaMesmoControladorTrade"
          elevation="2"
        >
          {{ this.$t('errors.transferencia.mesmo_controlador_trade') }}
        </v-alert>
        <v-row>
          <v-col>
            <v-text-field
              id="justificativa"
              name="justificativa"
              :disabled="somenteLeitura"
              v-model="novoAjuste.justificativa"
              :label="`${$tc('label.justificativa', 1)} *`"
              :rules="[rules.required]"
              :counter="150"
              maxlength="150"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="btn_voltar"
          v-if="somenteLeitura"
          @click.native="voltar">
          {{ $t('label.voltar') }}
        </v-btn>
        <v-btn id="voltar"
          v-if="!somenteLeitura"
          @click="abrirModalConfirmacao('voltar')">
          {{ $t('label.voltar') }}
        </v-btn>
        <v-btn id="concluir" @click="abrirModalConfirmacao('concluir')"
          color="red"
          dark
          v-if="exibeConcluir">
          {{ $t('label.concluir') }}
        </v-btn>
        <v-btn @click="abrirModalConfirmacao('solicitarAprovacao')"
          color="red"
          dark
          v-if="exibeSolicitarAprovacao">
          {{ $t('label.solicitar_aprovacao') }}
        </v-btn>
        <v-btn @click="abrirJustificativaAprovar()"
          dark
          color="primary"
          v-show="exibeBtnAvaliacao">
          {{ $t('label.aprovar') }}
        </v-btn>
        <v-btn @click="abrirJustificativaReprovar()"
          dark
          color="red"
          v-show="exibeBtnAvaliacao">
          {{ $t('label.reprovar') }}
        </v-btn>
        <v-btn @click="abrirJustificativaAnalise()"
          dark
          color="primary"
          v-show="exibeBtnAvaliacao">
          {{ $tc('label.enviar_analise', 1) }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao"
      @agree="executarAcao"
    />
    <workflow-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
    />
  </v-form>
</template>
<script>
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import AjusteVerbaFormFluxo from '../AjusteVerbaFormFluxo';
import WorkflowJustificativa from '../../../shared-components/workflow/WorkflowJustificativa';
import OrcamentoConfiguracao from '../../orcamento/OrcamentoConfiguracao';
import InputDecimal from '../../../shared-components/inputs/InputDecimal';

export default {
  name: 'AjusteVerbaTransferencia',
  data() {
    return {
      ajusteVerbaResource: this.$api.ajusteVerba(this.$resource),
      configuracaoResource: this.$api.orcamentoConfiguracao(this.$resource),
      configuracaoOrigem: null,
      configuracaoDestino: null,
      configuracaoOrigemCarregada: false,
      configuracaoDestinoCarregada: false,

      mensagemConfirmacao: null,
      acaoConfirmacao: null,

      novoAjuste: {
        id: null,
        tipoAjuste: 'TRANSFERENCIA',
        idTipoVerbaOrigem: null,
        idTipoVerbaDestino: null,
        idLinhaInvestimentoOrigem: null,
        idLinhaInvestimentoDestino: null,
        idOrcamentoOrigem: null,
        idOrcamentoDestino: null,
        idPeriodoOrcamentarioOrigem: null,
        idPeriodoOrcamentarioDestino: null,
        idControladorTradeDestino: null,
        idControladorTradeOrigem: null,
        status: 'EM_CADASTRO',
        valor: 0,
        justificativa: null,
      },
      status: 'Novo',
      mostrarAlertaSaldoNegativo: false,
      mostrarAlertaMesmoPeriodoOrcamentario: false,
      tipoVerbaListaOrigem: [],
      tipoVerbaListaDestino: [],
      orcamentoListaOrigem: [],
      orcamentoListaDestino: [],
      linhaInvestimentoListaOrigem: [],
      linhaInvestimentoListaDestino: [],
      periodoOrcamentarioListaOrigem: [],
      periodoOrcamentarioListaDestino: [],
      divisoesContaOrigem: [],
      divisoesContaDestino: [],
      disableTipoVerbaDestino: true,
      disableOrcamentoOrigem: true,
      disableOrcamentoDestino: true,
      disableLinhaInvestimentoOrigem: true,
      disableLinhaInvestimentoDestino: true,
      disablePeriodoOrcamentarioOrigem: true,
      disablePeriodoOrcamentarioDestino: true,
      disableDivisoesContaOrigem: true,
      disableDivisoesContaDestino: true,
      valorPlanejadoOrigem: 0,
      valorPlanejadoDestino: 0,
      saldoDisponivelOrigem: 0,
      saldoDisponivelDestino: 0,
      saldoPosTransferencia: 0,
      mostrarAlertaMesmoControladorTrade: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorAjusteNegativo: (value) => value > 0 || this.$t('errors.valor_negativo'),
      },
    };
  },
  mixins: [
    AjusteVerbaFormFluxo,
  ],
  props: {
    somenteLeitura: {
      type: Boolean,
      default: true,
    },
    novo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputDecimal,
    InputMoney,
    Confirm,
    WorkflowJustificativa,
  },
  computed: {
    ...generateComputed('AJUSTE_VERBA', [
      'canAccessPage',
    ]),
    exibeSolicitarAprovacao() {
      return this.configuracaoOrigemCarregada
            && this.configuracaoDestinoCarregada
            && this.possuiFluxoHabilitado
            && this.novoAjuste.status === 'EM_CADASTRO'
            && this.novoAjuste.solicitanteFluxo
            && this.transferenciaConfiguradaParaAmbosOrcamentos
            && !this.isOrcamentoDestinoIncompativel;
    },
    exibeConcluir() {
      return this.configuracaoOrigemCarregada
            && this.configuracaoDestinoCarregada
            && this.novoAjuste.status === 'EM_CADASTRO'
            && !this.possuiFluxoHabilitado
            && this.transferenciaConfiguradaParaAmbosOrcamentos
            && !this.isOrcamentoDestinoIncompativel;
    },
    possuiFluxoHabilitado() {
      return this.configuracaoOrigemCarregada
             && this.configuracaoOrigem.fluxoHabilitado;
    },
    isValorInvalido() {
      return this.novoAjuste
             && this.novoAjuste.valor > this.saldoDisponivelOrigem;
    },
    isOrcamentoDestinoIncompativel() {
      return this.configuracaoDestinoCarregada
            && !this.configuracaoDestino.permiteTransferenciaAjusteVerbaDiferente
            && this.novoAjuste.idTipoVerbaDestino
            && this.novoAjuste.idTipoVerbaOrigem
            && this.novoAjuste.idTipoVerbaDestino !== this.novoAjuste.idTipoVerbaOrigem;
    },
    transferenciaConfiguradaParaAmbosOrcamentos() {
      return this.configuracaoOrigemCarregada
             && this.configuracaoOrigem.permiteTransferencia
             && this.configuracaoDestinoCarregada
             && this.configuracaoDestino.permiteTransferencia;
    },
  },
  methods: {
    buscarTipoVerbaOrigem() {
      const param = {
        tipoAjuste: this.novoAjuste.tipoAjuste,
      };
      this.ajusteVerbaResource.listarTipoVerba(param)
        .then((res) => {
          this.tipoVerbaListaOrigem = res.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarOrcamentoOrigem(autocomplete) {
      const idTipoVerba = this.novoAjuste.idTipoVerbaOrigem;
      if (idTipoVerba !== null) {
        this.ajusteVerbaResource.listarOrcamento({ idTipoVerba, autocomplete })
          .then((res) => {
            this.orcamentoListaOrigem = res.data;
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    buscarLinhaInvestimentoOrigem(autocomplete) {
      const idTipoVerba = this.novoAjuste.idTipoVerbaOrigem;
      const idOrcamento = this.novoAjuste.idOrcamentoOrigem;
      const idPeriodoOrcamentario = this.novoAjuste.idPeriodoOrcamentarioOrigem;
      if (idTipoVerba !== null && idOrcamento !== null && idPeriodoOrcamentario !== null) {
        this.ajusteVerbaResource.listarLinhaInvestimento({
          idTipoVerba,
          idOrcamento,
          idPeriodoOrcamentario,
          autocomplete,
        })
          .then((res) => {
            this.linhaInvestimentoListaOrigem = res.data;
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    buscarPeriodoOrcamentarioOrigem(autocomplete) {
      const idTipoVerba = this.novoAjuste.idTipoVerbaOrigem;
      const idOrcamento = this.novoAjuste.idOrcamentoOrigem;
      if (idTipoVerba !== null && idOrcamento !== null) {
        this.ajusteVerbaResource.listarPeriodoOrcamentario({
          idTipoVerba,
          idOrcamento,
          autocomplete,
        })
          .then((res) => {
            this.periodoOrcamentarioListaOrigem = res.data;
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    buscarDivisoesContaOrigem(autocomplete = null) {
      const idLinhaInvestimento = this.novoAjuste.idLinhaInvestimentoOrigem;
      const idPeriodoOrcamentario = this.novoAjuste.idPeriodoOrcamentarioOrigem;
      this.ajusteVerbaResource.listarContaDivisao({
        idLinhaInvestimento,
        idPeriodoOrcamentario,
        autocomplete,
      }).then((res) => {
        this.divisoesContaOrigem = res.data;
      }, (err) => {
        this.$error(this, err);
      });
    },
    buscarDivisoesContaDestino(autocomplete = null) {
      const idLinhaInvestimento = this.novoAjuste.idLinhaInvestimentoDestino;
      const idPeriodoOrcamentario = this.novoAjuste.idPeriodoOrcamentarioDestino;
      this.ajusteVerbaResource.listarContaDivisao({
        idLinhaInvestimento,
        idPeriodoOrcamentario,
        autocomplete,
      }).then((res) => {
        this.divisoesContaDestino = res.data;
      }, (err) => {
        this.$error(this, err);
      });
    },
    changeTipoVerbaOrigem() {
      this.disableOrcamentoOrigem = !this.novoAjuste.idTipoVerbaOrigem;
      this.novoAjuste.idOrcamentoOrigem = null;
      this.novoAjuste.idLinhaInvestimentoOrigem = null;
      this.novoAjuste.idPeriodoOrcamentarioOrigem = null;
      this.novoAjuste.idControladorTradeOrigem = null;
      this.novoAjuste.idTipoVerbaDestino = null;
      this.valorPlanejadoOrigem = 0;
      this.saldoDisponivelOrigem = 0;
      this.novoAjuste.valor = 0;
      this.disableLinhaInvestimentoOrigem = true;
      this.disablePeriodoOrcamentarioOrigem = true;
      this.disableDivisoesContaOrigem = true;
      this.changeTipoVerbaDestino();
      this.buscarOrcamentoOrigem();
    },
    changeOrcamentoOrigem() {
      this.disablePeriodoOrcamentarioOrigem = !this.novoAjuste.idOrcamentoOrigem;
      this.novoAjuste.idLinhaInvestimentoOrigem = null;
      this.novoAjuste.idPeriodoOrcamentarioOrigem = null;
      this.novoAjuste.idControladorTradeOrigem = null;
      this.valorPlanejadoOrigem = 0;
      this.saldoDisponivelOrigem = 0;
      this.novoAjuste.valor = 0;
      this.disableLinhaInvestimentoOrigem = true;
      this.disableDivisoesContaOrigem = true;
      this.buscarPeriodoOrcamentarioOrigem();
      if (this.novoAjuste.idOrcamentoOrigem && this.novoAjuste.idTipoVerbaOrigem) {
        this.verificarUsuarioSolicitante();
        this.carregarConfiguracaoOrcamentoOrigem().then(() => {
          if (this.configuracaoOrigem.permiteTransferenciaAjusteVerbaDiferente) {
            this.disableTipoVerbaDestino = false;
          } else {
            this.disableTipoVerbaDestino = true;
            this.novoAjuste.idTipoVerbaDestino = this.novoAjuste.idTipoVerbaOrigem;
            this.changeTipoVerbaDestino();
          }
        });
      } else {
        this.configuracaoOrigemCarregada = false;
      }
    },
    changePeriodoOrcamentarioOrigem() {
      this.disableLinhaInvestimentoOrigem = !this.novoAjuste.idPeriodoOrcamentarioOrigem;
      this.novoAjuste.idLinhaInvestimentoOrigem = null;
      this.novoAjuste.idControladorTradeOrigem = null;
      this.valorPlanejadoOrigem = 0;
      this.saldoDisponivelOrigem = 0;
      this.novoAjuste.valor = 0;
      this.disableDivisoesContaOrigem = true;
      this.buscarLinhaInvestimentoOrigem();
    },
    changeLinhaInvestimentoOrigem() {
      const { idLinhaInvestimentoOrigem } = this.novoAjuste;
      this.disableDivisoesContaOrigem = !idLinhaInvestimentoOrigem;
      this.novoAjuste.idControladorTradeOrigem = null;
      if (!idLinhaInvestimentoOrigem) {
        this.valorPlanejadoOrigem = 0;
        this.saldoDisponivelOrigem = 0;
        this.novoAjuste.valor = 0;
      } else {
        this.buscarDivisoesContaOrigem();
        this.ajusteVerbaResource.getPlanejadoSaldo({
          idLinhaInvestimento: idLinhaInvestimentoOrigem,
          idPeriodoOrcamentario: this.novoAjuste.idPeriodoOrcamentarioOrigem,
        })
          .then((res) => {
            this.valorPlanejadoOrigem = res.data.planejado;
            this.saldoDisponivelOrigem = res.data.saldo;
            this.validaValor();
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    changeDivisoesContaOrigem() {
      const idControladorOrigem = this.novoAjuste.idControladorTradeOrigem;
      const param = {
        idLinhaInvestimento: this.novoAjuste.idLinhaInvestimentoOrigem,
        idPeriodoOrcamentario: this.novoAjuste.idPeriodoOrcamentarioOrigem,
      };
      if (idControladorOrigem) {
        param.idControlador = idControladorOrigem;
      }
      this.ajusteVerbaResource.getPlanejadoSaldo(param)
        .then((res) => {
          this.valorPlanejadoOrigem = res.data.planejado;
          this.saldoDisponivelOrigem = res.data.saldo;
          this.validaValor();
        }, (err) => {
          this.$error(this, err);
        });
    },
    changeDivisoesContaDestino() {
      const idControladorDestino = this.novoAjuste.idControladorTradeDestino;
      this.mostrarAlertaMesmoControladorTrade = idControladorDestino === this.novoAjuste.idControladorTradeOrigem
        && this.novoAjuste.idLinhaInvestimentoDestino === this.novoAjuste.idLinhaInvestimentoOrigem
        && this.configuracaoOrigem.rateioDivisao;
      const param = {
        idLinhaInvestimento: this.novoAjuste.idLinhaInvestimentoDestino,
        idPeriodoOrcamentario: this.novoAjuste.idPeriodoOrcamentarioDestino,
      };
      if (idControladorDestino) {
        param.idControlador = idControladorDestino;
      }
      if (idControladorDestino) {
        this.ajusteVerbaResource.getPlanejadoSaldo(param)
          .then((res) => {
            this.valorPlanejadoDestino = res.data.planejado;
            this.saldoDisponivelDestino = res.data.saldo;
            if (this.configuracaoOrigemCarregada && this.configuracaoOrigem.ehPercentual) {
              this.saldoPosTransferencia = this.saldoDisponivelDestino + (this.novoAjuste.valor * 1);
            } else {
              this.saldoPosTransferencia = this.saldoDisponivelDestino + this.novoAjuste.valor;
            }
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    buscarTipoVerbaDestino() {
      const param = {
        tipoAjuste: this.novoAjuste.tipoAjuste,
      };
      this.ajusteVerbaResource.listarTipoVerba(param)
        .then((res) => {
          this.tipoVerbaListaDestino = res.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarOrcamentoDestino(autocomplete) {
      const idTipoVerba = this.novoAjuste.idTipoVerbaDestino;
      if (idTipoVerba !== null) {
        this.ajusteVerbaResource.listarOrcamento({ idTipoVerba, autocomplete })
          .then((res) => {
            this.orcamentoListaDestino = res.data;
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    buscarPeriodoOrcamentarioDestino(autocomplete) {
      const idTipoVerba = this.novoAjuste.idTipoVerbaDestino;
      const idOrcamento = this.novoAjuste.idOrcamentoDestino;
      if (idTipoVerba !== null && idOrcamento !== null) {
        this.ajusteVerbaResource.listarPeriodoOrcamentario({
          idTipoVerba,
          idOrcamento,
          autocomplete,
        })
          .then((res) => {
            this.periodoOrcamentarioListaDestino = res.data;
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    buscarLinhaInvestimentoDestino(autocomplete) {
      const idTipoVerba = this.novoAjuste.idTipoVerbaDestino;
      const idOrcamento = this.novoAjuste.idOrcamentoDestino;
      const idPeriodoOrcamentario = this.novoAjuste.idPeriodoOrcamentarioDestino;
      if (idTipoVerba !== null && idOrcamento !== null && idPeriodoOrcamentario !== null) {
        this.ajusteVerbaResource.listarLinhaInvestimento({
          idTipoVerba,
          idOrcamento,
          idPeriodoOrcamentario,
          autocomplete,
        })
          .then((res) => {
            this.linhaInvestimentoListaDestino = res.data;
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    changeTipoVerbaDestino() {
      this.disableOrcamentoDestino = !this.novoAjuste.idTipoVerbaDestino;
      this.novoAjuste.idOrcamentoDestino = null;
      this.novoAjuste.idLinhaInvestimentoDestino = null;
      this.novoAjuste.idPeriodoOrcamentarioDestino = null;
      this.novoAjuste.idControladorTradeDestino = null;
      this.valorPlanejadoDestino = 0;
      this.saldoDisponivelDestino = 0;
      this.saldoPosTransferencia = 0;
      this.disableLinhaInvestimentoDestino = true;
      this.disablePeriodoOrcamentarioDestino = true;
      this.mostrarAlertaMesmoPeriodoOrcamentario = false;
      this.disableDivisoesContaDestino = true;
      this.buscarOrcamentoDestino();
    },
    changeOrcamentoDestino() {
      this.disablePeriodoOrcamentarioDestino = !this.novoAjuste.idOrcamentoDestino;
      this.novoAjuste.idLinhaInvestimentoDestino = null;
      this.novoAjuste.idPeriodoOrcamentarioDestino = null;
      this.novoAjuste.idControladorTradeDestino = null;
      this.valorPlanejadoDestino = 0;
      this.saldoDisponivelDestino = 0;
      this.saldoPosTransferencia = 0;
      this.disableLinhaInvestimentoDestino = true;
      this.mostrarAlertaMesmoPeriodoOrcamentario = false;
      this.disableDivisoesContaDestino = true;
      this.buscarPeriodoOrcamentarioDestino();
      if (this.novoAjuste.idOrcamentoDestino && this.novoAjuste.idTipoVerbaDestino) {
        this.carregarConfiguracaoOrcamentoDestino();
      } else {
        this.configuracaoDestinoCarregada = false;
      }
    },
    changePeriodoOrcamentarioDestino() {
      this.disableLinhaInvestimentoDestino = !this.novoAjuste.idPeriodoOrcamentarioDestino;
      this.mostrarAlertaMesmoPeriodoOrcamentario = this.novoAjuste.idPeriodoOrcamentarioOrigem
        === this.novoAjuste.idPeriodoOrcamentarioDestino
        && this.novoAjuste.idLinhaInvestimentoOrigem
        === this.novoAjuste.idLinhaInvestimentoDestino
        && !this.configuracaoOrigem.rateioDivisao;
      this.novoAjuste.idControladorTradeDestino = null;
      this.novoAjuste.idLinhaInvestimentoDestino = null;
      this.valorPlanejadoDestino = 0;
      this.saldoDisponivelDestino = 0;
      this.saldoPosTransferencia = 0;
      this.disableDivisoesContaDestino = true;
      this.buscarLinhaInvestimentoDestino();
    },
    changeLinhaInvestimentoDestino() {
      this.mostrarAlertaMesmoPeriodoOrcamentario = this.novoAjuste.idPeriodoOrcamentarioOrigem
        === this.novoAjuste.idPeriodoOrcamentarioDestino
        && this.novoAjuste.idLinhaInvestimentoOrigem
        === this.novoAjuste.idLinhaInvestimentoDestino
        && !this.configuracaoOrigem.rateioDivisao;

      const idLinhaInvestimento = this.novoAjuste.idLinhaInvestimentoDestino;
      this.disableDivisoesContaDestino = !idLinhaInvestimento;
      this.novoAjuste.idControladorTradeDestino = null;
      if (!idLinhaInvestimento) {
        this.valorPlanejadoDestino = 0;
        this.saldoDisponivelDestino = 0;
        this.saldoPosTransferencia = 0;
      } else {
        this.buscarDivisoesContaDestino();
        this.ajusteVerbaResource.getPlanejadoSaldo({
          idLinhaInvestimento: this.novoAjuste.idLinhaInvestimentoDestino,
          idPeriodoOrcamentario: this.novoAjuste.idPeriodoOrcamentarioDestino,
        })
          .then((res) => {
            this.valorPlanejadoDestino = res.data.planejado;
            this.saldoDisponivelDestino = res.data.saldo;
            if (this.configuracaoOrigemCarregada && this.configuracaoOrigem.ehPercentual) {
              this.saldoPosTransferencia = this.saldoDisponivelDestino + (this.novoAjuste.valor * 1);
            } else {
              this.saldoPosTransferencia = this.saldoDisponivelDestino + this.novoAjuste.valor;
            }
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    validaValor() {
      this.mostrarAlertaSaldoNegativo = this.novoAjuste.valor > this.saldoDisponivelOrigem;
      if (this.configuracaoOrigemCarregada && this.configuracaoOrigem.ehPercentual) {
        this.saldoPosTransferencia = this.saldoDisponivelDestino + (this.novoAjuste.valor * 1);
      } else {
        this.saldoPosTransferencia = this.saldoDisponivelDestino + this.novoAjuste.valor;
      }
    },
    voltar() {
      this.$router.push({
        name: this.$route.params.from || 'ajuste-verba',
      });
    },
    concluir() {
      if (!this.$refs.form.validate()
          || this.isValorInvalido
          || this.mostrarAlertaMesmoPeriodoOrcamentario
          || this.mostrarAlertaMesmoControladorTrade
          || this.isOrcamentoDestinoIncompativel) return;

      this.salvarEConcluir();
    },
    carregarConfiguracaoOrcamentoOrigem() {
      const idTipoVerba = this.novoAjuste.idTipoVerbaOrigem;

      const promise = this.configuracaoResource.buscarConfigVigente({ idTipoVerba });

      return promise.then((res) => {
        this.configuracaoOrigem = new OrcamentoConfiguracao(res.data);
        this.configuracaoOrigemCarregada = true;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracaoOrcamentoDestino() {
      const idTipoVerba = this.novoAjuste.idTipoVerbaDestino;

      const promise = this.configuracaoResource.buscarConfigVigente({ idTipoVerba });

      return promise.then((res) => {
        this.configuracaoDestino = new OrcamentoConfiguracao(res.data);
        this.configuracaoDestinoCarregada = true;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abrirModalConfirmacao(acao) {
      if (acao === 'voltar') {
        this.mensagemConfirmacao = this.$t('message.tem_certeza_cancelar');
        this.acaoConfirmacao = 'voltar';
      } else if (acao === 'concluir') {
        if (!this.$refs.form.validate()
          || this.isValorInvalido
          || this.mostrarAlertaMesmoPeriodoOrcamentario
          || this.mostrarAlertaMesmoControladorTrade
          || this.isOrcamentoDestinoIncompativel) return;
        this.mensagemConfirmacao = this.$t('message.deseja_concluir', { text: this.$tc('label.ajuste_verba', 1).toLowerCase() });
        this.acaoConfirmacao = 'concluir';
      } else if (acao === 'solicitarAprovacao') {
        if (this.mostrarAlertaMesmoPeriodoOrcamentario
            || this.mostrarAlertaMesmoControladorTrade
            || this.isOrcamentoDestinoIncompativel) return;
        this.mensagemConfirmacao = this.$t('message.deseja_solicitar_aprovacao_ajuste_verba');
        this.acaoConfirmacao = 'solicitarAprovacao';
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    executarAcao() {
      if (this.acaoConfirmacao === 'voltar') {
        this.voltar();
      } else if (this.acaoConfirmacao === 'concluir') {
        this.concluir();
      } else if (this.acaoConfirmacao === 'solicitarAprovacao') {
        this.salvarESolicitarAprovacao();
      }
    },
    carregarDados(idAjusteVerba) {
      this.ajusteVerbaResource.pegarAjusteVerbaPorId({ idAjusteVerba })
        .then((res) => {
          const status = res.data.status.toLowerCase();
          this.status = this.$tc(`status_entidade.${status}`, 1);
          this.novoAjuste.id = idAjusteVerba;
          this.novoAjuste.valor = res.data.valor;
          this.novoAjuste.status = res.data.status;
          this.novoAjuste.justificativa = res.data.justificativa;
          this.novoAjuste.idTipoVerbaOrigem = res.data.id_tipo_verba_origem;
          this.novoAjuste.idOrcamentoOrigem = res.data.id_orcamento_origem;
          this.novoAjuste.idLinhaInvestimentoOrigem = res.data.id_linha_investimento_origem;
          this.novoAjuste.idPeriodoOrcamentarioOrigem = res.data.id_periodo_orcamentario_origem;
          this.novoAjuste.idTipoVerbaDestino = res.data.id_tipo_verba_destino;
          this.novoAjuste.idOrcamentoDestino = res.data.id_orcamento_destino;
          this.novoAjuste.idLinhaInvestimentoDestino = res.data.id_linha_investimento_destino;
          this.novoAjuste.idPeriodoOrcamentarioDestino = res.data.id_periodo_orcamentario_destino;
          this.novoAjuste.idPeriodoOrcamentarioDestino = res.data.id_periodo_orcamentario_destino;
          this.novoAjuste.idPeriodoOrcamentarioOrigem = res.data.id_periodo_orcamentario_origem;
          this.novoAjuste.idControladorTradeDestino = res.data.id_controlador_trade_destino;
          this.novoAjuste.idControladorTradeOrigem = res.data.id_controlador_trade_origem;
          this.valorPlanejadoDestino = res.data.valor_planejado;
          this.saldoDisponivelDestino = res.data.saldo_disponivel;
          this.valorPlanejadoOrigem = res.data.valor_planejado_origem;
          this.saldoDisponivelOrigem = res.data.saldo_disponivel_origem;
          if (this.novoAjuste.status !== 'APROVADO'
              && this.novoAjuste.status !== 'REPROVADO') {
            if (this.configuracaoOrigemCarregada && this.configuracaoOrigem.ehPercentual) {
              this.saldoPosTransferencia = this.saldoDisponivelDestino + (this.novoAjuste.valor * 1);
            } else {
              this.saldoPosTransferencia = this.saldoDisponivelDestino + this.novoAjuste.valor;
            }
          }
          this.disableOrcamentoOrigem = false;
          this.disableOrcamentoDestino = false;
          this.disableLinhaInvestimentoOrigem = false;
          this.disableLinhaInvestimentoDestino = false;
          this.disablePeriodoOrcamentarioOrigem = false;
          this.disablePeriodoOrcamentarioDestino = false;
          this.buscarTipoVerbaOrigem();
          this.buscarTipoVerbaDestino();
          this.buscarOrcamentoOrigem();
          this.buscarOrcamentoDestino();
          this.verificarUsuarioSolicitante();
          this.verificarUsuarioAprovador();
          this.carregarConfiguracaoOrcamentoOrigem();
          this.carregarConfiguracaoOrcamentoDestino();
          this.buscarPeriodoOrcamentarioOrigem();
          this.buscarPeriodoOrcamentarioDestino();
          this.buscarLinhaInvestimentoOrigem();
          this.buscarLinhaInvestimentoDestino();
          if (this.novoAjuste.idControladorTradeDestino) {
            this.disableDivisoesContaDestino = false;
            this.buscarDivisoesContaDestino();
          }
          if (this.novoAjuste.idControladorTradeOrigem) {
            this.disableDivisoesContaOrigem = false;
            this.buscarDivisoesContaOrigem();
          }
        }, (err) => {
          this.$error(this, err);
        });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    carregarSeUsuarioPossuirAcesso(idAjuste) {
      this.ajusteVerbaResource.verificarSeUsuarioPossuiAcesso({ idAjuste })
        .then((res) => {
          if (res.data) {
            this.carregarDados(idAjuste);
          } else {
            this.$router.push({ name: 'inicio' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
      return;
    }

    if (this.novo) {
      this.buscarTipoVerbaOrigem();
      this.buscarTipoVerbaDestino();
    } else {
      const { id, from } = this.$route.params;
      if (!from) {
        this.carregarSeUsuarioPossuirAcesso(id);
      } else {
        this.carregarDados(id);
      }
    }
  },
};
</script>
